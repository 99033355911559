
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    
import React, { useEffect, useState, useRef } from 'react'
import i18nConfig from '../../i18n'
import appWithI18n from 'next-translate/appWithI18n'
import useTranslation from 'next-translate/useTranslation'
import UCookies from 'universal-cookie'
import Axios from 'axios'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '@/styles/global.css'
import '@/styles/swiper-custom.scss'
import '@/styles/base.scss'
import '@/styles/mapBox.scss'
import { initializeProjectStore, initializeStore } from '@/utils/propertyStore'
import { SchemaMarkup } from '@/utils/schemaMarkup'
import { useRouter } from 'next/router'
import Head from 'next/head'
import mixpanel from 'mixpanel-browser'
import DashboardProvider from '@/lib/DashboardProvider'
import { authCheck } from '@/utils/authCheck'
import WalletProvider from '@/lib/WalletProvider'

Axios.defaults.baseURL = process.env.NEXT_PUBLIC_SERVER_BASE_URL + '/api'
Axios.defaults.withCredentials = true
const MyApp = ({ Component, pageProps }) => {
  const { t, lang } = useTranslation('translations-auction')
  const router = useRouter()
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)

  mixpanel.init(process.env.NEXT_PUBLIC_AUCTION_MIXPANEL)
  toast.configure({ limit: 3, rtl: lang == 'ar' ? true : false })
  initializeStore()
  initializeProjectStore()

  //call refresh token api when page loads
  if (typeof window !== 'undefined') {
    authCheck()
  }

  /**
   * Handle app lang
   * @return void
   */
  useEffect(() => {
    //dependency on refresh-token api
    window.lang = lang
    ;(async () => {
      const direction = lang === 'en' ? 'ltr' : 'rtl'
      setDirection(direction, lang)
    })()
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [lang])

  useEffect(() => {
    setIsFirstLoadDone(true)
    const cookie = new UCookies()
    if (cookie.get('authToken')) {
      localStorage.setItem('userId', cookie.get('userId'))
      localStorage.setItem('authToken', cookie.get('authToken'))
      localStorage.setItem('refreshToken', cookie.get('refreshToken'))
      localStorage.setItem('authStoredDate', cookie.get('authStoredDate'))
      localStorage.setItem('encryptedPhoneNumber', cookie.get('encryptedPhoneNumber'))
    } else {
      localStorage.removeItem('userId')
      localStorage.removeItem('authToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('authStoredDate')
      localStorage.removeItem('encryptedPhoneNumber')
    }

    document.addEventListener('click', authCheck)

    return () => {
      document.removeEventListener('click', authCheck)
    }
  }, [])

  /**
   * Set app dircetion and language
   * @return void
   */
  const setDirection = (direction, language) => {
    document.documentElement.dir = direction
    document.documentElement.lang = language
  }
  const breadCrumb =
    router.pathname == '/auction/[auction-id]'
      ? [
          {
            name: pageProps?.propertyDetailsRevamp?.propertyInfo?.city,
            item: `${lang == 'en' ? '/en' : ''}?cityId=${pageProps?.auctionDetails?.cityId}`,
          },
          {
            name: pageProps?.propertyDetailsRevamp?.propertyInfo?.title,
            item: `${lang == 'en' ? '/en' : ''}/auction/${pageProps?.params?.['auction-id']}`,
          },
        ]
      : []

  const path = router.asPath.split('?')
  const queryString = path.length > 1 ? `?${path[1]}` : ''
  return (
    <>
      <Head>
        <meta name='google' content='notranslate' />
        {process.env.NEXT_PUBLIC_ADD_NO_INDEX == 'true' && <meta name='robots' content='noindex'></meta>}
        {isFirstLoadDone && (
          <link href={`${process.env.NEXT_PUBLIC_CDN_BASE_URL}/fonts/iconography.css`} rel='stylesheet' as='style' />
        )}
      </Head>
      {router.pathname == '/' && SchemaMarkup(t, lang, `${queryString}`, `${queryString}`, { schemaType: 'Home' })}
      {router.pathname == '/auction/[auction-id]' &&
        SchemaMarkup(
          t,
          lang,
          `/auction/${pageProps.params?.['auction-id']}`,
          `/auction/${pageProps.params?.['auction-id']}`,
          {
            breadcrumbsList: breadCrumb,
            propertyDetails: {
              ...pageProps?.propertyDetailsRevamp,
              propertyInfo: {
                ...pageProps?.propertyDetailsRevamp?.propertyInfo,
                salePrice: pageProps?.auctionDetails?.bidStartingPrice,
                highestBid: pageProps?.auctionDetails?.highestBid,
              },
            },
            propertyImage:
              process.env.NEXT_PUBLIC_BASE_FILE_URL +
              '/properties/' +
              pageProps?.propertyDetailsRevamp?.id +
              '/images/' +
              pageProps?.galleryData?.images?.data?.[0]?.content +
              '?q=75',
            schemaType: 'DetailPage',
          },
        )}
      {
        <WalletProvider>
          <DashboardProvider>
            <Component {...pageProps} />
          </DashboardProvider>
        </WalletProvider>
      }
    </>
  )
}

const __Page_Next_Translate__ = appWithI18n(MyApp, i18nConfig)


    export default __appWithI18n(__Page_Next_Translate__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      
    });
  