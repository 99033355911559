import { io } from "socket.io-client";
import { isUserLoggedIn } from "./utils";

export const MASTERPLAN_LANDING_ENDPOINT='/s/masterplan'

export const PROPERTY_SORT_TYPES = [
	"attribute.salePrice_desc",
	"attribute.salePrice_asc",
	"attribute.completionYear_desc",
	"attribute.completionYear_asc",
	"size_desc",
	"size_asc",
];

export const SURROUNDING_RADIUS = 5 * 1000; //5 km
export const GOOGLE_PLACE_BASE_URL =
  "https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=";
export const GOOGLE_API_KEY = "AIzaSyAwDHDGuHGhCzlwIOyqOrPFURvHDMWLG08";
export const DEFAULT_ERROR_STRING = "Some thing wrong Error";
export const DEFAULT_ERROR_STATUS = 500;
export const API_RESPONSE_SUCCESS = 200;
export const API_RESPONSE_CREATE_SUCCESS = 201;
export const API_RESPONSE_AUTHENTICATION_ERROR = 401;

export const getType = (index) => {
	switch (index) {
		case 0:
			return "store";
		case 1:
			return "restaurant";
		case 2:
			return "mosque";
		case 3:
			return "hospital";
		case 4:
			return "school";
	}
};
export const NumberRegex =/^[0-9\b]+$/;

export const getDropdownFilters = (t) =>{
	return [
		{
			isOpen: false,
			title: t('auctionCommon.SELLER_TYPE'),
			defaultTitle: t('auctionCommon.SELLER_TYPE'),
			filter_key: 'auction_seller',
			isActive:false,
			showClear:true,
			multiSelect: false,
			className: "mobileDropdodownOpenArrowPosition_1",
			values: [],
		},
		{
			isOpen: false,
			title: t('auctionCommon.AUCTION_STATUS'),
			defaultTitle: t('auctionCommon.AUCTION_STATUS'),
			filter_key: 'auction_status',
			isActive:false,
			showClear:true,
			multiSelect: true,
			className: "mobileDropdodownOpenArrowPosition_2",
			values: [],
		},
	]
}

export const attributeDataKeys = {
  noOfBedrooms: 'bedrooms',
  noOfBathrooms: 'bathrooms',
  builtUpArea: 'builtUpArea',
  carpetArea: 'carpetArea',
  noOfLivingrooms: 'livingrooms',
  noOfGuestrooms: 'guestrooms',
  furnishingType: 'furnishType',
  completionYear: 'completionYear',
  noofUnitsAvailable: 'noofUnitsAvailable',
}

// this function is used to send types of filters we need to fetch from backend API
export const getFilterTypes =() =>[
	"auction_status",
	"auction_seller"
]

export const getContactType = (index) => {
	switch (index) {
		case 0:
			return "call_us";
		case 1:
			return "chat_now";
		case 2:
			return "other_enquiry";
		case 3:
	}
};

export const getAuctionType = (type) => {
	switch (type) {
		case "all":
			return "all";
		case "auction-live":
			return "live";
		case "auction-past":
			return "past";
		case "auction-upcoming":
			return "upcoming";
		case "auction-featured":
			return "featured";
		case "auction-trending":
			return "trending";
		case "cancel":
			return "cancel";

		default:
			return "all";
	}
};

export const socket = io(process.env.NEXT_PUBLIC_SOCKET_BASE_URL, {
	transports: ['websocket'],
	auth: {
		token:"cd630dd09cf0e21ae25f3a8c17b235226706fbfc29b30d016064096ec07e2181"
	},
	query: {
		source: 'auction:web',
		platform: ''
	}
});

/**
All auction status
Author: Rohit
*/
export const ALL_STATUS_OF_AUCTION = {
	UPCOMING:"upcoming",
	LIVE:"live",
	PAST:"past"
};

/**
 * Domain 
 */


export const DOMAIN_WITH_COMMON_COOKIES = [
	"auction.wasalt.com",
	"preprod-auction.wasalt.com",
  "auction-qa.wasalt.com",
];
export const profileSideMenuTabs= (t,lang)=>{
  const homepageUrl = lang == 'en' ? '/en' : ''
  return({ 
    sidemenu: [
        {
            id: 1,
            icon: "icon-home",
            title:t('common.PROPERTIES'),
            desc: t('common.EXPLORE_PROPERTIES'),
            isLink: false,
            isOpen: false,
            isLoginShow:true,
            eventName:'profile_properties_clicked',
            childTab: [
              {
                childId: 1,
                childIcon: "search-properties",
                childTitle:t('common.SEARCH_PROPERTIES'),
                isShowWithoutLogin: true,
                isAddProperty:false,
                childEventName:'profile_searched_clicked',
                redirectURL:`${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/sale/search?propertyFor=sale&countryId=1&cityId=273`
            },
                {
                    childId: 2,
                    childIcon: "property-sold",
                    childTitle:t('common.CONTACTED_AND_SEEN'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profile_contacted_seen_clicked',
                    redirectURL:`${homepageUrl}/user/properties?pType=history&type=contacted_property`
                },
                {
                    childId: 3,
                    childIcon: "heart",
                    childTitle: t('common.SAVED_PROPERTIES'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profle_saved_itmes_clicked',
                    redirectURL:`${homepageUrl}/user/properties?pType=saved&type=properties`
                },
                {
                    childId: 4,
                    childIcon: "my-booking",
                    childTitle: t('common.MY_BOOKINGS'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_my_bookings_clicked',
                    redirectURL:`${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/user/my-bookings?type=units`
                }
            ]
        },
        {
            id: 2,
            icon: "icon-document",
            title: t('common.REQUEST_YOUR_PROPERTY'),
            desc: isUserLoggedIn() ? t('common.VIEW_OR_EDIT_REQUIREMENT') : t('common.SHARE_YOUR_REQUIREMENT'),
            isLink: true,
            isOpen: false,
            isLoginShow:true,
            eventName:'profile_req_your_prop_clicked',
            redirectURL: isUserLoggedIn() ? `${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/user/myrequirements?source=MyProfile_click`:`${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/post-requirement`
        },
        {
            id: 3,
            icon: "icon-auction-outline",
            title: t('common.AUCTIONS'),
            desc:t('common.LIVE_UPCOMING_CLOSED'),
            isOpen: false,
            isLink: false,
            isLoginShow:true,
            redirectURL:`/`,
            eventName:'profile_Auction_clicked',
            childTab: [
                {
                    childId: 1,
                    childIcon: "auction-outline",
                    childTitle: t('common.ALL_AUCTIONS'),
                    isShowWithoutLogin: true,
                    isAddProperty:false,
                    childEventName:'profile_all_auction_clicked',
                    redirectURL:`/`
                },
                {
                    childId: 2,
                    childIcon: "registered-auctions",
                    childTitle: t('common.REGISTERED_AUCTIONS_REVAMP'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_registered_auction_clicked',
                    redirectURL:`/user/auctions?pType=register&type=live`
                },
                {
                    childId: 3,
                    childIcon: "notification",
                    childTitle: t('common.FOLLOWED_AUCTIONS'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_followed_auction_clicked',
                    redirectURL:`/user/auctions?pType=followed&type=upcoming`
                },
                {
                    childId: 4,
                    childIcon: "wallet",
                    childTitle: t('common.AUCTIONS_EWALLET'),
                    isShowWithoutLogin: isUserLoggedIn(),
                    isAddProperty:false,
                    childEventName:'profile_ewallet_auction_clicked',
                    redirectURL:`/user/auctions?pType=eWallet`
                }
            ]
        },
        {
          id: 4,
          icon: "icon-manage-properties",
          title: t('common.Manage_Properties'),
          desc: t('common.Manage_Properties_SUB_TXT'),
          isLink: false,
          isOpen: false,
          isLoginShow:isUserLoggedIn(),
          eventName:'profile_manage_your_properties_clicked',
          childTab: [
              {
                  childId: 1,
                  childIcon: "home",
                  childTitle: t('common.My_Property'),
                  isShowWithoutLogin: true,
                  isAddProperty:false,
                  childEventName:'profile_my_properties_MP',
                  redirectURL:`${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/user/myproperty`
              },
              {
                  childId: 2,
                  childIcon: "post-property",
                  childTitle: t('common.ADD_PROPERTY'),
                  isShowWithoutLogin: true,
                  isAddProperty:true,
                  childEventName:'profile_add_property_MP',
                  redirectURL:`${process.env.NEXT_PUBLIC_BASE_URL}${homepageUrl}/post-property`
              },
          ]
      },
    ]

})}

export const helpAndSupportOptions = (t,lang)=>{
  return([
  {
    title:t('common.HELP_AND_SUPPORT'),
    title_icon:'icon-information',
    icon_right:lang==='en' ? 'icon-chevron-right' :'icon-chevron-left',
    icon_up:'icon-chevron-up',
    icon_down: 'icon-chevron-down',
    isContact:true,
    eventName:'profile_helpNsupport_Clicked',
      options: [
        {
          optionTitle: t('PrivacyPolicy.PRIVACY_HEADING'),
          isPDF:false,
          redirectURL: `${process.env.NEXT_PUBLIC_BASE_URL}${lang == 'en' ? '/en' : ''}/s/privacy-policy`
        },
        {
          optionTitle: t('common.TERMS_OF_USE'),
          isPDF:false,
          redirectURL: `${process.env.NEXT_PUBLIC_BASE_URL}${lang == 'en' ? '/en' : ''}/s/terms-of-use`
        },
        {
          optionTitle: t('common.TERMS_OF_ADVERTISING'),
          isPDF:false,
          redirectURL: `${process.env.NEXT_PUBLIC_BASE_URL}${lang == 'en' ? '/en' : ''}/s/terms-of-advertising`
        },
        {
          optionTitle: t('common.AUCTION_TERMS_AND_CONDITION'),
          isPDF:false,
          redirectURL: `${process.env.NEXT_PUBLIC_BASE_URL}${lang == 'en' ? '/en' : ''}/s/terms-n-conditions-for-auction`
        }
      ]
  },
  {
    title:t('common.Lang'),
    title_icon:'icon-language',
    icon_right:'',
    icon_down:'',
    isContact:false,
    eventName:'profile_language_clicked',
    options: []
  }
])}
export const howAuctionWorksAndRechargeEwalletTab = (t, _lang, auctionVideoId = "", _auctionPDF = "") => {
  return {
    options: [
      {
        optionTitle: t('common.HOW_AUCTIONS_WORKS'),
        isPDF: true,
        redirectURL: `https://youtu.be/${auctionVideoId}`
      },
    ]
  }
}

export const withdrawNumberMobile = '+9668001233227';
export const withdrawNumberWa = '9668001233227';
export const numberCall = '8001233227';

//eWallet History filter options
export const historyFilterOptions = (t) => [
	{slug: "on_hold", text: t('auctionGroupDetail.blocked')},
	{slug: "refunded", text: t('auctionGroupDetail.reversed')},
	{slug: "credited", text: t('auctionGroupDetail.credited')},
	{slug: "debited", text: t('auctionGroupDetail.debited')},
	{slug: "withdraw_failed", text: t('auctionGroupDetail.withdrawalFailed')},
	{slug: "withdraw_successful", text: t('auctionGroupDetail.withdrawalSuccessful')},
	{slug: "withdraw_requested", text: t('auctionGroupDetail.withdrawRequested')},
]

export const price = {
  buy: [
    { type: 'less', min: 0, max: 500000 },
    { type: 'between', min: 500000, max: 1000000 },
    { type: 'between', min: 1000000, max: 2000000 },
    { type: 'more', min: 2000000, max: 10000000 },
  ],
  rent: [
    { type: 'less', min: 0, max: 60000 },
    { type: 'between', min: 60000, max: 80000 },
    { type: 'between', min: 80000, max: 100000 },
    { type: 'more', min: 100000, max: 500000 },
  ],
}

export const ALL_SELLER_TYPE =  {
  ALL:"all",
  INFATH:"au-infath",
  WASALT:"au-wasalt",
};

export const bankDataConst = {
  RIZE_BANK_SLUG: 'rize-bank',
  MORTGAGE_BANK_SLUG : "dar-al-tamleek"
}

export const SYRConstant = {
  BANK_LOGO: 'logo',
  BANK_NAME: 'name',
  BANK_DATA: 'data',
  BANK_SLUG: 'slug',
  SALE: 'sale',
  REQ_BUY:'req-buy',
  RENT: 'rent'
}



export const liveGifen = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/banners/Live%20Tag_en%20animation.gif`
export const liveGifar = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/manuals/banners/Live%20Tag_arabic%20animation.gif`
export const sadadCDNurl = `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/images/SADAD_Logo.png`

export const getLanguage = { EN: 'en',AR: 'ar' }